import React, { useContext, useEffect } from "react";
import { Numbers } from "../../components/Numbers";
import Banner from "../../images/smc-t20-INDvsSA_with_bgc.png";
import { Benefits } from "../../components/Benefits";
import { Excellence } from "../../components/Excellence";
import Esteps from "../../images/4-steps.webp";
import { Pinvest } from "../../components/Pinvest";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer";
import { Context } from "../../context";
import { leadApi } from "../../utils/APICollection";

import { sendEkycOtpApi } from "../../utils/APICollection";
import axios from "axios";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import SEO from "../../components/common/SEO";
import { seoData } from "../../utils/Data";
import { CAPTCHA_KEY, EKYC_CAPTCHA_KEY } from "../../utils/constants";
import {
  loadReCaptchaScript,
  loadReCaptchaScript1,
} from "../../components/common/FormattedDate";
import DematFaq from "./DematFaq";

export const OpenDematAccount = ({ catID }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("refcode");
  const {
    setIsDemat,
    name,
    num,
    categoryID,
    setCategoryID,
    setUrlData,
    setNum,
    ipAddress,
    email,
    city,
    state,
    setIPAddress,
    loading,
    setLoading,
    successMessage,
    setSuccessMessage,
    deviceID,
    setDeviceID,
    setLeadId,
    source,
    setSource,
  } = useContext(Context);

  const [mobileError, setMobileError] = React.useState();

  const handleNumChange = (e) => {
    setNum(e.target.value);
    setMobileError("");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    let mobErr = "";
    if (num?.toString().length !== 10) {
      mobErr = "Please fill out this field";
    }
    if (mobErr) {
      setMobileError(mobErr);
      setLoading(false);
    } else {
      try {
        getIP();

        setUrlData({
          ref: searchParams.get("utm_refcode"),
          utm_source: searchParams.get("utm_source"),
          medium: searchParams.get("utm_medium"),
          campaign: searchParams.get("utm_campaign"),
        });
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(String(CAPTCHA_KEY), { action: "submit" })
            .then(async (token) => {
              const { data: data1, messagel } = await leadApi({
                mobile_no: num,
                captchaToken: token,
                leadInfo: [
                  {
                    user_name: name ? name : "",
                    contact_number: num,
                    email: email ? email : "",
                    city: city ? city : "",
                    state: state ? state : "",
                    category_code: "DEMAT",
                    country: "",
                    product: "DEMAT",
                    source: "Smc Website | DEMAT",
                    ref: searchParams.get("utm_refcode"),
                    utm_source: searchParams.get("utm_source"),
                    medium: searchParams.get("utm_medium"),
                    campaign: searchParams.get("utm_campaign"),
                    ip: ipAddress,
                    device: deviceID,
                    status: "Otp sent",
                    client_id: "",
                    sub_query: "",
                    existing_customer: "",
                    explain_query: "",
                  },
                ],
              });
              if (data1) {
                setLeadId(String(data1?.data[0]));
                window.grecaptcha.ready(() => {
                  window.grecaptcha
                    .execute(String(EKYC_CAPTCHA_KEY), { action: "submit" })
                    .then(async (token) => {
                      const data = await sendEkycOtpApi({
                        mobile_number: num,
                        source: "web",
                        partner_id: "smc",
                        device_code: "",
                        captcha_token: token,
                      });

                      if (data?.data?.status === "success") {
                        setLoading(false);
                        setCategoryID("DEMAT");
                        setUrlData({
                          ref: searchParams.get("utm_refcode"),
                          utm_source: searchParams.get("utm_source"),
                          medium: searchParams.get("utm_medium"),
                          campaign: searchParams.get("utm_campaign"),
                        });
                        setIsDemat(true);
                        navigate("/validateotp");
                      } else if (
                        data?.error?.response?.data?.error ===
                        "User details already exist"
                      ) {
                        setSuccessMessage("You are already registered with us");
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 2000);
                        setLoading(false);
                        setNum("");
                      } else if (
                        data?.error?.response?.data?.error ===
                        "Something went wrong"
                      ) {
                        setSuccessMessage(
                          "Uh - oh!, This was not supposed to happen. Please try after sometime"
                        );
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 2000);
                        setLoading(false);
                        setNum("");
                      } else {
                        setSuccessMessage(data?.error?.response?.data?.error);
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 2000);
                        setLoading(false);
                        setNum("");
                      }
                    });
                });
              }
            });
        });
      } catch (error) {
        setMobileError("");
      }
    }
  };
  const getSourceByCategory = (categoryID) => {
    const categorySourceMap = {
      DEMAT: "SMC Website | DEMAT",
    };

    return categorySourceMap[categoryID] || "SMC Website | DEMAT";
  };

  const getIP = async () => {
    try {
      const userAgent = navigator.userAgent;
      const res = await axios.get("https://api.ipify.org?format=json");

      setIPAddress(res?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;
    setSource("Smc Website | DEMAT");
    if (/Android/i.test(userAgent)) {
      setDeviceID("Android device.");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceID("iOS device.");
    } else if (/Macintosh/i.test(userAgent)) {
      setDeviceID("macOS device.");
    } else if (/Windows/i.test(userAgent)) {
      setDeviceID("Windows device.");
    } else {
      setDeviceID("Unknown Device");
    }
    getIP();
    loadReCaptchaScript();
    loadReCaptchaScript1();
  }, []);

  React.useEffect(() => {
    getIP();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header />

      <SEO
        title={seoData.openDemat.title}
        description={seoData.openDemat.description}
        name="Company name."
        type="article"
        url={seoData.openDemat.url}
        image={seoData.openDemat.image}
      />

      <section id="banner">
        <div className="banner internal bannercent">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Demat-Account</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>
                    Open Free <span>Demat Account</span>
                  </h1>

                  <div className="creativehead">
                    <div className="demataccount">
                      <h3>Doorway to Investments. Get Unlimited Benefits.</h3>
                      <form onSubmit={onSubmit}>
                        <div className="demataccountinput">
                          <div className="demataccountfrm">
                            <div className="forminputsite">
                              <div className="phonemange">
                                <span>
                                  <strong className="text-black">+91</strong>
                                </span>
                              </div>
                              <input
                                type="Number"
                                placeholder="Enter your mobile number"
                                value={num}
                                onChange={handleNumChange}
                                style={{
                                  borderColor: mobileError ? "red" : "",
                                }}
                                error={!!mobileError}
                                helperText={mobileError}
                                minLength={10}
                                maxLength={10}
                                // required
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, e.target.maxLength);
                                }}
                                inputProps={{
                                  minLength: 10,
                                  maxLength: 10,
                                  type: "number",
                                }}
                                disabled={loading}
                              />
                            </div>

                            <button type="submit">Open Free Demat A/C</button>
                          </div>
                        </div>
                      </form>
                      {mobileError && (
                        <p style={{ color: "red", marginLeft: "5rem" }}>
                          {mobileError}
                        </p>
                      )}
                      {successMessage && (
                        <p
                          style={{
                            color: "red",
                            marginLeft: "2rem",
                            width: "100%",
                          }}
                        >
                          {successMessage}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bannergt">
                  <img src={Banner} alt="Hassle-free investing" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <Benefits />
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>It’s time to get you going!</h3>
                    <p>
                      Open your Demat Account now through a seamless process
                    </p>
                  </div>
                  <div className="downloadapp easystep">
                    <div className="apprgt">
                      <img src={Esteps} alt="easysteps" />
                    </div>
                    <div className="applef">
                      <div className="stepstn">
                        <ul>
                          <li>
                            <strong>Step 1 </strong>
                            <p>Sign up with SMC </p>
                          </li>
                          <li>
                            <strong>Step 2 </strong>
                            <p>Verify your contact details</p>
                          </li>
                          <li>
                            <strong>Step 3 </strong>
                            <p>Complete your KYC</p>
                          </li>
                          <li>
                            <strong>Step 4 </strong>
                            <p>Your trading account is ready</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pinvest />
          <Numbers />
          <Excellence />
          <DematFaq />
        </section>
      </main>
      <Footer />
    </>
  );
};
