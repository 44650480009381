import React, { useEffect, useContext } from "react";
import { Numbers } from "../components/Numbers";
import { Reserch } from "../components/Reserch";
import { Benefits } from "../components/Benefits";
import { Pinvest } from "../components/Pinvest";
import { Learning } from "../components/Learning";
import Footer from "../components/Footer";
import { Context } from "../context";
import { NavLink } from "react-router-dom";
import Loader from "../components/Loader";
import Header from "../components/Header";
import CarouselWebinar from "../components/CarouselWebinar";
import SEO from "../components/common/SEO";
import { ExcellenceNew } from "../components/ExcellenceNew";
import { HOME_SEO_DESC, HOME_SEO_TITLE, SEO_URL } from "../utils/constants";
import SingleFieldForm from "../components/common/SingleFieldForm";
import AllStocks from "../components/common/AllStocks";
import ReferAndEarn from "../components/ReferAndEarn";
import { seoData } from "../utils/Data";
import HomeBanner from "../../src/images/smc-t20-INDvsSA_with_bgc.png";

export const Home = ({ catID }) => {
  const isMobile = window.innerWidth <= 540;
  const { isDemat, setIsDemat, loading } = useContext(Context);

  useEffect(() => {
    setIsDemat(false);
  }, [isDemat]);
  return (
    <>
      <Header />
      <SEO
        title={seoData.home.title}
        description={seoData.home.description}
        name="Company name."
        type="article"
        url={seoData.home.url}
        image={seoData.home.image}
      />
      <section id="banner">
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="bannermg">
                {isMobile && (
                  <div className="bannergt">
                    <img
                      src={HomeBanner}
                      alt="laptop"
                      loading="dns-prefetch"
                      // style={{ marginTop: 70, marginBottom: 60 }}
                    />
                  </div>
                )}
                <div className="bannerlt">
                  <h1>Invest. Like a Pro.</h1>
                  <p>
                    <strong>
                      30+ Years of Experience | 2 Million Satisfied Customers
                    </strong>
                  </p>
                  <div className="creativehead">
                    <div className="demataccount">
                      <SingleFieldForm catID={catID} />
                    </div>
                  </div>
                </div>
                <div className="bannergt desktop-show">
                  <img
                    src={HomeBanner}
                    alt="laptop"
                    loading="dns-prefetch"
                    // style={{ marginTop: 70, marginBottom: 60 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <Benefits />
          <Numbers />
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Hot Stocks. Choose Now.</h3>
                    <a>Time & Tide waits for none, so Invest Now!</a>
                  </div>

                  <div className="stockslider">
                    <AllStocks isMobile={isMobile} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pinvest />
          <Reserch />
          <div
            className="webcent pt-0"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <span>Reports</span>
                    <h3>Track. Analyse. Invest.</h3>
                  </div>
                  {/* <div className="reportslider"> */}
                  <div className="reportbox">
                    <div className="reportlogo">
                      <span>
                        <img src="./images/stockn.webp" alt="Stock Analysis" />
                      </span>
                      <h4 style={{ padding: "10px" }}>Stock Analysis</h4>
                    </div>
                    <div className="reportlogo">
                      <span>
                        <img
                          src="./images/derivativiesn.webp"
                          alt="Derivatives Analysis"
                        />
                      </span>
                      <h4 style={{ padding: "10px" }}>Derivatives Analysis</h4>
                    </div>
                    <div className="reportlogo">
                      <span>
                        <img
                          src="./images/currencyn.webp"
                          alt="Currency Analysis"
                        />
                      </span>
                      <h4 style={{ padding: "10px" }}>Currency Analysis</h4>
                    </div>
                  </div>
                  <div className="mbtn">
                    <div className="btnn">
                      <span>
                        <NavLink to="/research">Know More</NavLink>
                      </span>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent pt-0"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="minicontainer">
                    <div className="title">
                      {!isMobile ? (
                        <h3>Our Work. Your Testimony.</h3>
                      ) : (
                        <>
                          <h3>Our Work. Your</h3> <h3>Testimony.</h3>
                        </>
                      )}
                    </div>
                    <CarouselWebinar />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ExcellenceNew />
          <Learning />
          <ReferAndEarn isMobile={isMobile} />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};
export default Home;
